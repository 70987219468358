import { CssBaseline } from '@mui/material';

import CustomNotification from './components/CustomInputComponents/CustomNotification';
import AppFooter from './components/footer/AppFooter';
import Spinner from './components/spinner';
import AppContextProvider from './context/AppContext';
import ThemeContextProvider from './context/ThemeContext';
import { useSpinnerState } from './lib/LoadSpinnerStore';
import { AppRoutes } from './routes';

function App() {
  const spinnerState = useSpinnerState((state) => state.spinnerState);
  return (
    <AppContextProvider>
      <ThemeContextProvider>
        <CssBaseline>
          <CustomNotification />
          {spinnerState && <Spinner />}
          <AppRoutes />
          <AppFooter />
        </CssBaseline>
      </ThemeContextProvider>
    </AppContextProvider>
  );
}

export default App;
