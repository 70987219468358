import amplitude from 'amplitude-js';
const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY as string;
amplitude.getInstance().init(API_KEY, undefined, {
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
});

export const setAmplitudeUserId = (userId: string) => amplitude.getInstance().setUserId(userId);

const logAmplitudeEvent = (action: string, data: any) =>
  amplitude.getInstance().logEvent(action, data);

export default logAmplitudeEvent;
