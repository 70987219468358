import { WorkspaceLogoDTO, WorkspaceSettingsDTO } from '../..';
import axios from '../../../axios';
import IClientInvoice from '../types/IClientInvoice';
import INvoiceFile from '../types/INvoiceFIle';
import { InvoiceTemplateDTO } from '../types/InvoiceTemplateDTO';
import IPaymentStatus from '../types/IPaymentStatus';
import IPaymentTypesDetails from '../types/IPaymentTypeDetails';
import IPaymentTypeUpdate from '../types/IPaymentTypeUpdate';
import ITaxStatus from '../types/ITaxStatus';
import ITaxTypesDetails from '../types/ITaxTypeDetails ';
import ITransacationDetails from '../types/ITransacationDetails';

const url = '/invoicingservice/api';

export const getAllPaymentType = () => axios.get(`${url}/paymentTypes`);

export const savePaymentType = (paymentType: IPaymentTypesDetails) =>
  axios.post(`${url}/create-paymentType`, paymentType);

export const updatePaymentType = (id: string, paymentType: IPaymentTypeUpdate) =>
  axios.put(`${url}/update-paymentType/${id}`, paymentType);

export const updatePaymentVisibilityStatus = (id: string, status: IPaymentStatus) =>
  axios.put(`${url}/update-visibilityStatus/${id}`, status);

// Tax Type
export const getAllTaxType = () => axios.get(`${url}/taxTypes`);

export const getAllVisibleTaxType = () => axios.get(`${url}/tax-types`);

export const saveTaxType = (taxType: ITaxTypesDetails) =>
  axios.post(`${url}/create-TaxType`, taxType);

export const updateTaxVisibilityStatus = (id: string, status: ITaxStatus) =>
  axios.put(`${url}/update-taxvisibilityStatus/${id}`, status);

export const updateTaxType = (id: string, status: ITaxTypesDetails) =>
  axios.put(`${url}/update-taxationValue/${id}`, status);

export const getManualTaxation = () => axios.get(`${url}/taxation-settings`);

export const updateManualTaxation = (manual: boolean) =>
  axios.post(`${url}/taxation-settings`, null, { params: { manual } });

export const getTaxTypeList = () => axios.get(`${url}/taxCollection`);

// Transaction
export const saveTranscationDetails = (transcationDetails: ITransacationDetails) =>
  axios.post(`${url}/addTranscation`, transcationDetails);

export const getAllClientInvoices = () => axios.get(`${url}/client-invoicing/list`);

export const getTranscationDetails = () => axios.get(`${url}/getTranscation`);

export const getTaxByTaxTypeList = (applyTo: string) =>
  axios.get(`${url}/tax-by-tax-type?applyTo=${applyTo}`);

export const getTaxByByApplyToTaxesList = (applyToTaxes: string[]) =>
  axios.get(`${url}/tax-by-apply-to-taxes?applyToTaxes=${applyToTaxes}`);

export const saveInvoiceFile = (payload: INvoiceFile) =>
  axios.post(`${url}/invoice-file/save-file`, payload);

export const getClientInvoiceInfo = (id: string) =>
  axios.get<IClientInvoice>(`${url}/client-invoicing/${id}`);

export const getClientInvoiceFileList = (id: string) =>
  axios.get(`${url}/invoice-file/by-invoice-id/${id}`);

export const getTranscationDetailsByInvoiceId = (id: string) =>
  axios.get(`${url}/getTranscationListByInvoiceId/${id}`);

export const updateTransactionRecordById = (id: string, transcationDetails: ITransacationDetails) =>
  axios.put(`${url}/update-transcationValue/${id}`, transcationDetails);

// Invoice Template
const templateUrl = `${url}/invoice-template`;

export const saveInvoiceTemplate = (template: InvoiceTemplateDTO) =>
  axios.post<InvoiceTemplateDTO>(templateUrl, template);

export const getInvoiceTemplate = (id?: string) =>
  axios.get(templateUrl, { params: { ...(id && { id }) } });

export const cloneInvoiceTemplate = (id: string) =>
  axios.post(`${templateUrl}/clone`, null, { params: { id } });

export const deleteInvoiceTemplate = (id: string) => axios.delete(templateUrl, { params: { id } });

// Workspace
export const getWorkspaceSettings = () =>
  axios.get<WorkspaceSettingsDTO>(`${url}/workspace-settings`);

export const updateDefaultTemplate = (defaultTemplateId: string) =>
  axios.post<WorkspaceSettingsDTO>(`${url}/workspace-settings`, null, {
    params: { defaultTemplateId },
  });

export const updateWorkspaceSettings = (dto: WorkspaceSettingsDTO) =>
  axios.post<WorkspaceSettingsDTO>(`${url}/workspace-settings`, dto);

const workspaceLogoUrl = `${url}/workspacelogo`;
export const saveLogoDetails = (workspaceLogoDto: WorkspaceLogoDTO) => axios.post<WorkspaceLogoDTO>(workspaceLogoUrl, workspaceLogoDto);

export const getWorkspaceLogoForOrgEntity = (orgEntityId: string, logoType: string) =>
  axios.get<WorkspaceLogoDTO>(`${workspaceLogoUrl}/getlogobyorgentity/${orgEntityId}/logotype/${logoType}`);

export const getAllLogos = () => axios.get<any>(`${workspaceLogoUrl}`);

export const deleteLogoById = (logoId: string, orgEntityId: string) => axios.delete<any>(`${workspaceLogoUrl}/logo/${logoId}/orgentity/${orgEntityId}`);
