import { useState, useEffect, Dispatch } from 'react';
import { getSiteSettingsCareerPages, IAppContext } from 'wisestep-api';

import AdminSettings from '../default/admin';
import { useSpinnerState } from '../lib/LoadSpinnerStore';

const useFetchSettings = (): [
  boolean,
  boolean,
  IAppContext,
  Dispatch<React.SetStateAction<IAppContext>>
] => {
  const [fetching, setFetching] = useState<boolean>(true);
  const [notFound, setIsNotFound] = useState<boolean>(false);
  const [settings, setSettings] = useState<IAppContext>({} as IAppContext);
  const { setSpinnerState } = useSpinnerState();
  useEffect(() => {
    setFetching(true);
    setSpinnerState(true);
    const fetchSettings = async () => {
      let slug = '';
      if (process.env.REACT_APP_BUILD_ENV === 'slug') {
        slug = window.location.href.split('/')[3];
      }
      if (slug && slug === 'jobs') {
        setSettings(AdminSettings);
      } else {
        let response: any = {};
        await getSiteSettingsCareerPages()
          .then((settingResponse) => {
            response = settingResponse.data;
          })
          .catch(() => {
            setSpinnerState(false);
            setIsNotFound(true);
          });

        if (!response || !response.slug) {
          setIsNotFound(true);
        } else {
          setIsNotFound(false);
        }
        if (response) {
          const siteSettings = {
            appearanceSettings: JSON.parse(response.appearanceSettings),
            candidatesSettings: JSON.parse(response.candidatesSettings),
            generalSettings: JSON.parse(response.generalSettings),
            integrationSettings: JSON.parse(response.integrationSettings),
            isPreview: response.isPreview,
            jobsSettings: JSON.parse(response.jobsSettings),
            slug: response.slug,
            userId: response.userId,
            domain: response.domain,
          };
          setSettings(siteSettings);
        }
      }
      setFetching(false);
    };
    fetchSettings();
  }, [setSpinnerState]);
  return [fetching, notFound, settings, setSettings];
};
export default useFetchSettings;
