enum JobAssignmentStatusFilter {
  All = 'All',
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
  Closed = 'Closed',
  Accepted = 'Accepted',
  NotAssigned = 'NotAssigned',
  Open = 'Open',
  Assigned_Accepted = 'Assigned_Accepted',
  Everything = 'Everything',
  Posted = 'Posted',
}

export default JobAssignmentStatusFilter;
