import axios from '../../../axios';
axios.defaults.withCredentials = false;
import { ICandidate, ISearchRequest } from '../../../types';

const SETTINGS_ENDPOINT = '/career-page';
const JOBS_ENDPOINT = '/career-page/jobs';
const UPLOAD_ENDPOINT = '/career-page/upload-file';
const APPLY_ENDPOINT = '/career-page/apply';
const JOB_ENDPOINT = '/career-page/job';
const SIMILAR_JOBS_ENDPOINT = '/career-page/similar-jobs/';

const settingUrl = () => {
  let settingUrl = '';
  let slug = '';
  if (process.env.REACT_APP_BUILD_ENV === 'slug') {
    slug = window.location.href.split('/')[3];
    settingUrl = `${SETTINGS_ENDPOINT}/slug/${slug}`;
  } else if (process.env.REACT_APP_BUILD_ENV === 'cname') {
    const domain = window.location.hostname;
    settingUrl = `${SETTINGS_ENDPOINT}/domain/${domain}`;
  }
  return settingUrl;
};

export const getSiteSettingsCareerPages = () => axios.get(settingUrl());

export const getJobByIdCareerPages = (jobId: string) => axios.get(`${JOB_ENDPOINT}/${jobId}`);

export const SearchCareerPagesJobs = (request: ISearchRequest) =>
  axios.post(`${JOBS_ENDPOINT}`, request);

export const SearchCareerPagesSimilarJobs = (request: ISearchRequest, hideJob: string) =>
  axios.post(`${SIMILAR_JOBS_ENDPOINT}${hideJob}`, request);

export const ApplyForJob = (request: ICandidate) => axios.put(`${APPLY_ENDPOINT}`, request);

export const uploadFileCareerPages = (file: File) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return axios.post(`${UPLOAD_ENDPOINT}`, formData, {
    headers: {
      'Content-Type': file.type,
    },
  });
};
