import { Box, Button, Container, Typography } from '@mui/material';
import React from 'react';
interface ErrorProps {
  errorText?: string;
  pageRefresh?: boolean;
}

const Error = ({ errorText = 'Something went wrong', pageRefresh = false }: ErrorProps) => (
  <Container component="main" maxWidth="xs">
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        {errorText}
      </Typography>
      <Button
        onClick={() => {
          pageRefresh ? window.location.assign(window.location.origin) : window.location.reload();
        }}
      >
        Refresh
      </Button>
    </Box>
  </Container>
);

export default Error;
