import { createTheme, ThemeProvider } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import tinycolor from 'tinycolor2';
import { WsColors } from 'wisestep-base';

import { useAppContext } from './AppContext';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    white: true;
    backArrow: true;
  }
}
type AppProviderProps = {
  children: React.ReactNode;
};
export default function ThemeContextProvider({
  children,
}: React.PropsWithChildren<AppProviderProps>) {
  const settings = useAppContext();
  const [favIconUrl] = useState(
    settings?.appearanceSettings?.faviconURL
      ? settings?.appearanceSettings?.faviconURL
      : '/favicon.ico'
  );
  const primary = settings?.appearanceSettings?.primaryColor || '#00B4D8';
  const primaryText = settings?.appearanceSettings?.primaryTextColor || '#000000';
  const secondary = settings?.appearanceSettings?.secondaryColor || '#CAF0F8';
  const secondaryText = settings?.appearanceSettings?.secondaryTextColor || '#6E6E6E';
  const lightenRate = 7.5;
  const darkenRate = 10;
  const primaryTextLight = tinycolor(settings?.appearanceSettings?.primaryTextColor || '#000000')
    .lighten(20)
    .toHexString();
  const theme = createTheme({
    palette: {
      //   type: 'light',
      primary: {
        main: primary,
        light: tinycolor(primary).lighten(lightenRate).toHexString(),
        dark: tinycolor(primary).darken(darkenRate).toHexString(),
      },
      secondary: {
        main: secondary,
        light: tinycolor(secondary).lighten(lightenRate).toHexString(),
        dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      },
      text: {
        primary: primaryText,
        secondary: secondaryText,
      },
      background: {
        default: settings?.appearanceSettings?.mainBackgroundColor,
      },
    },
    typography: {
      fontFamily: 'Manrope',
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: primaryTextLight,
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h5' },
            style: {
              fontWeight: '700',
            },
          },
          {
            props: { variant: 'h6' },
            style: {
              fontWeight: '400',
            },
          },
          {
            props: { variant: 'body1' },
            style: {
              fontWeight: '400',
            },
          },
          {
            props: { variant: 'body2' },
            style: {
              fontWeight: '400',
            },
          },
          {
            props: { variant: 'subtitle1' },
            style: {
              fontWeight: '500',
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'text' },
            style: {
              textTransform: 'none',
            },
          },
          {
            props: { variant: 'contained' },
            style: {
              color: WsColors.White,
              '&:disabled': {
                backgroundColor: secondary,
                color: secondaryText,
              },
            },
          },
          {
            props: { variant: 'backArrow' },
            style: {
              color: secondaryText,
              textTransform: 'none',
              '&:hover': {
                background: 'none',
              },
            },
          },
          {
            props: { size: 'large' },
            style: {
              fontWeight: '700',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              fontWeight: '600',
            },
          },
          {
            props: { size: 'small' },
            style: {
              fontWeight: '600',
            },
          },
        ],
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            '&.MuiToggleButtonGroup-root': {
              backgroundColor: secondary,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.MuiToggleButton-root': {
              backgroundColor: secondary,
              textTransform: 'none',
              borderColor: 'none',
              fontWeight: 'bold',
              color: primaryText,
              border: 'none',
              '&:hover': {
                backgroundColor: 'none',
                fontWeight: 'bold',
                borderRadius: 5,
              },
              '&.Mui-selected': {
                backgroundColor: primary,
                color: primaryText,
                fontWeight: 'bold',
                boxShadow: '2px 2px 2px 2px' + secondary,
                borderRadius: 5,
              },
              '&.Mui-disabled': {
                backgroundColor: WsColors.TheBlues100,
                color: WsColors.White,
                fontWeight: 'bold',
                borderRadius: 5,
                '&.Mui-selected': {
                  backgroundColor: WsColors.TertiaryShades100,
                  color: WsColors.TheBlues300,
                  fontWeight: 'bold',
                  boxShadow: '2px 2px 2px 2px' + WsColors.GreyBlack,
                  borderRadius: 5,
                },
              },
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="icon" href={favIconUrl} />
        <title>{`${settings?.generalSettings?.companyName} - Careers Page`}</title>
      </Helmet>
      {children}
    </ThemeProvider>
  );
}
