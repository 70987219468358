import axios from '../../../axios';
import IOutlookCalendarEvent from '../types/IOutlookCalendarEvent';

const url = 'emailingapp/api/outlook-auth';

export const getOutlookCalendarAccounts = () => axios.get(`${url}/calendar-accounts`);

export const createOutlookCalendarEvent = (payload: IOutlookCalendarEvent) =>
  axios.post(`${url}/create-calendar-event`, payload);

export const fetchOutlookCalendarEvents = (payload: IOutlookCalendarEvent) =>
  axios.post(`${url}/fetch-calendar-events`, payload);

export const updateOutlookCalendarEvent = (payload: IOutlookCalendarEvent) =>
  axios.post(`${url}/update-calendar-event`, payload);

export const deleteOutlookCalendarEvent = (payload: IOutlookCalendarEvent) =>
  axios.post(`${url}/delete-calendar-event`, payload);
