import { lazyImport } from '../utils/lazyImport';

const { HomePageRoutes } = lazyImport(
  () => import('../features/Home/routes/HomePageRoutes'),
  'HomePageRoutes'
);
const { JobPageRoutes } = lazyImport(
  () => import('../features/Job/routes/JobPageRoutes'),
  'JobPageRoutes'
);
const baseUrl = process.env.REACT_APP_BUILD_ENV == 'slug' ? '/:slug' : '';
export const publicRoutes = [
  {
    path: baseUrl + '/*',
    element: <HomePageRoutes />,
  },
  {
    path: baseUrl + '/similar-jobs/*',
    element: <HomePageRoutes />,
  },
  {
    path: baseUrl + '/:jobId/*',
    element: <JobPageRoutes />,
  },
];
