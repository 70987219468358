import { Notification } from 'wisestep-base';

import { useStore } from '../../../lib/NotificationStore';

const WsCustomNotification = () => {
  const notification = useStore((state) => state.notification);
  const notify = useStore((state) => state.notify);
  return (
    <Notification
      horizontal="right"
      autoHideDuration={1000}
      notify={notify}
      notificationObject={notification}
    />
  );
};
export default WsCustomNotification;
