import create, { SetState } from 'zustand';

export interface Notification {
  message: string;
  type: 'error' | 'info' | 'success' | 'warning';
}

export interface NotificationState {
  notification: Notification;
  notify: boolean;
  setNotification: (notification: Notification) => void;
  closeNotification: () => void;
}

export const useStore = create<NotificationState>((set: SetState<NotificationState>) => ({
  notification: {
    message: '',
    type: 'info',
  },
  notify: false,
  setNotification: (notifyMsg: Notification) => {
    set({ notification: notifyMsg, notify: true });
  },
  closeNotification: () => {
    set({ notify: false });
  },
}));
