import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useState } from 'react';

const AlertBar = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface INotification {
  vertical?: 'top' | 'bottom';
  horizontal: 'center' | 'left' | 'right';
  autoHideDuration?: number;
  notificationObject?: { message: string; type: 'error' | 'info' | 'success' | 'warning' };
  notify: boolean;
}

const Notification: React.FC<INotification> = ({
  vertical,
  horizontal,
  autoHideDuration,
  notificationObject,
  notify,
}) => {
  const autoHideDurationProp = autoHideDuration ? autoHideDuration : 6000;

  const verticalAlignment: 'top' | 'bottom' = vertical ? vertical : 'top';

  const horizontalAlignment: 'center' | 'left' | 'right' = horizontal ? horizontal : 'center';

  const [open, setOpen] = useState(false);

  const [notification, setNotification] = useState<{
    message: string;
    type: 'error' | 'info' | 'success' | 'warning';
  }>();

  React.useEffect(() => {
    if (notificationObject) {
      setNotification(notificationObject);
      setOpen(notify);
    }
  }, [notify, notificationObject]);

  const closeNotification = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: verticalAlignment, horizontal: horizontalAlignment }}
      open={open}
      autoHideDuration={autoHideDurationProp}
      onClose={closeNotification}
    >
      <AlertBar
        onClose={closeNotification}
        severity={notification && notification.type ? notification.type : 'success'}
        sx={{ width: '100%' }}
      >
        {notification && notification.message ? notification.message : ''}
      </AlertBar>
    </Snackbar>
  );
};

export default Notification;
