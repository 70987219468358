import create, { SetState } from 'zustand';

export interface SpinnerState {
  spinnerState: boolean;
  setSpinnerState: (showSpinner: boolean) => void;
}

export const useSpinnerState = create<SpinnerState>((set: SetState<SpinnerState>) => ({
  spinnerState: false,
  setSpinnerState: (showSpinner: boolean) => {
    set({ spinnerState: showSpinner });
  },
}));
