import axios from '../../../axios';
import { ITeam } from '../../TeamSearchService';
import DataSharingSettingsDTO from '../types/DataSharingSettingsDTO';
import DataSharingSettingsJobDTO from '../types/DataSharingSettingsJobDTO';
import IAddTeamMembersPayload from '../types/IAddTeamMembersPayload';
import ICreateTeam from '../types/ICreateTeam';

const teamsUrl = '/api/teams/';

const membersUrl = '/api/team-members/';

const wsreportingUrl = '/wsreporting/api/audittrial/byentity/';

const candidateUrl = '/candidatesearch/api/';

const jobsUrl = '/jobsearch/api/';

export const createTeam = (data: ICreateTeam) => axios.post(`${teamsUrl}`, data);

export const editTeam = (data: ICreateTeam) => axios.put(`${teamsUrl}`, data);

export const addTeamMembers = (data: IAddTeamMembersPayload) =>
  axios.put(`${membersUrl}add-team-members`, data);

export const deleteTeamMember = (memberEmail: string, teamId: string) =>
  axios.delete(`${membersUrl + memberEmail}/${teamId}`);

export const deleteTeamMemberFromAllTeams = (memberEmail: string) =>
  axios.delete(`${membersUrl + memberEmail}`);

export const getTeamHistory = (teamId: string) => axios.get(wsreportingUrl + teamId);
export const getDataSharingSettings = () => axios.get(`${candidateUrl}data-sharing-settings`);
export const updateDataSharingSettings = (config: DataSharingSettingsDTO) =>
  axios.post(`${candidateUrl}data-sharing-settings`, config);

export const getJobsDataSharingSettings = () => axios.get(`${jobsUrl}data-sharing-settings`);
export const updateJobsDataSharingSettings = (config: DataSharingSettingsJobDTO) =>
  axios.post(`${jobsUrl}data-sharing-settings`, config);

export const getAllTeams = () => axios.get(`${teamsUrl}team`);
export const updateTeams = (config: ITeam) => axios.put(`${teamsUrl}`, config);

export const getUserTeamsCount = () => axios.get(`${teamsUrl}users/count`);

export const getTeamsByUser = (email: string) => axios.get(`${teamsUrl}user?email=${email}`);
