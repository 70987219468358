import React from 'react';

interface IWisestepLogo {
  height?: number;
  width?: number;
}

const WisestepLogo: React.FC<IWisestepLogo> = ({ height = 32, width = 146 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 146 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="8" fill="#00B4D8" />
    <path d="M12.2173 23.2568L9.86517 9H5.60896L8.18509 23.2568H12.2173Z" fill="white" />
    <path d="M19.8311 23.2568L22.1832 9H26.4394L23.8633 23.2568H19.8311Z" fill="white" />
    <path d="M23.8537 23.2568L18.2042 9H13.7976L19.786 23.2568H23.8537Z" fill="white" />
    <path d="M8.33844 23.2568L13.9072 9H18.2509L12.348 23.2568H8.33844Z" fill="white" />
    <path
      d="M47.2331 22.8438H51.4684L53.4083 15.2159H53.5457L55.4856 22.8438H59.7128L62.954 9.75284H58.9854L57.3608 18.0795H57.2557L55.4128 9.75284H51.5412L49.7468 18.1392H49.6417L47.9686 9.75284H44L47.2331 22.8438Z"
      fill="#03045E"
    />
    <path
      d="M64.5402 22.8438H68.4927V9.75284H64.5402V22.8438ZM66.5205 8.22728C67.644 8.22728 68.5493 7.33239 68.5493 6.23296C68.5493 5.13353 67.644 4.24716 66.5205 4.24716C65.4051 4.24716 64.4917 5.13353 64.4917 6.23296C64.4917 7.33239 65.4051 8.22728 66.5205 8.22728Z"
      fill="#03045E"
    />
    <path
      d="M81.8474 13.75C81.6372 11.1591 79.6246 9.58239 76.2622 9.58239C72.8998 9.58239 70.7579 11.125 70.774 13.75C70.7579 15.7614 71.9784 17.0568 74.4598 17.5426L76.6259 17.9773C77.6524 18.1903 78.1212 18.5313 78.1374 19.1108C78.1212 19.767 77.4261 20.2017 76.4158 20.2017C75.2923 20.2017 74.5325 19.6903 74.3628 18.7358L70.4588 18.8381C70.7417 21.429 72.8513 23.0909 76.3996 23.0909C79.7216 23.0909 82.1384 21.3438 82.1545 18.6506C82.1384 16.7415 80.9421 15.608 78.4688 15.1051L76.0763 14.6278C75.0256 14.4063 74.6861 14.0142 74.6942 13.5114C74.6861 12.8466 75.4216 12.4375 76.335 12.4375C77.3776 12.4375 78.1051 13.0256 78.2182 13.8523L81.8474 13.75Z"
      fill="#03045E"
    />
    <path
      d="M89.9038 23.0909C93.1612 23.0909 95.3031 21.429 95.7557 18.8551L92.1266 18.7443C91.8194 19.6136 91.0112 20.0824 89.9766 20.0824C88.457 20.0824 87.5194 19.017 87.5194 17.4148V17.304H95.7961V16.2642C95.7961 11.9517 93.3067 9.58239 89.7826 9.58239C86.0322 9.58239 83.6236 12.2926 83.6236 16.3494C83.6236 20.5426 85.9999 23.0909 89.9038 23.0909ZM87.5194 14.8835C87.576 13.5795 88.554 12.5909 89.8634 12.5909C91.1647 12.5909 92.1023 13.5455 92.1185 14.8835H87.5194Z"
      fill="#03045E"
    />
    <path
      d="M108.654 13.75C108.444 11.1591 106.431 9.58239 103.069 9.58239C99.7062 9.58239 97.5642 11.125 97.5804 13.75C97.5642 15.7614 98.7847 17.0568 101.266 17.5426L103.432 17.9773C104.459 18.1903 104.928 18.5313 104.944 19.1108C104.928 19.767 104.232 20.2017 103.222 20.2017C102.099 20.2017 101.339 19.6903 101.169 18.7358L97.2652 18.8381C97.5481 21.429 99.6577 23.0909 103.206 23.0909C106.528 23.0909 108.945 21.3438 108.961 18.6506C108.945 16.7415 107.748 15.608 105.275 15.1051L102.883 14.6278C101.832 14.4063 101.492 14.0142 101.501 13.5114C101.492 12.8466 102.228 12.4375 103.141 12.4375C104.184 12.4375 104.911 13.0256 105.025 13.8523L108.654 13.75Z"
      fill="#03045E"
    />
    <path
      d="M117.915 9.75284H115.676V6.61648H111.723V9.75284H110.082V12.821H111.723V19.1875C111.699 21.8381 113.332 23.1676 116.12 23.0313C117.074 22.9801 117.761 22.7756 118.141 22.6563L117.543 19.6477C117.373 19.6903 116.977 19.7756 116.678 19.7756C116.039 19.7756 115.676 19.5028 115.676 18.7273V12.821H117.915V9.75284Z"
      fill="#03045E"
    />
    <path
      d="M125.601 23.0909C128.859 23.0909 131 21.429 131.453 18.8551L127.824 18.7443C127.517 19.6136 126.709 20.0824 125.674 20.0824C124.154 20.0824 123.217 19.017 123.217 17.4148V17.304H131.494V16.2642C131.494 11.9517 129.004 9.58239 125.48 9.58239C121.73 9.58239 119.321 12.2926 119.321 16.3494C119.321 20.5426 121.697 23.0909 125.601 23.0909ZM123.217 14.8835C123.273 13.5795 124.251 12.5909 125.561 12.5909C126.862 12.5909 127.8 13.5455 127.816 14.8835H123.217Z"
      fill="#03045E"
    />
    <path
      d="M133.512 27.7528H137.465V20.7216H137.545C138.047 21.9318 139.162 23.0313 141.037 23.0313C143.785 23.0313 146 20.7642 146 16.3068C146 11.6875 143.656 9.58239 141.061 9.58239C139.097 9.58239 138.022 10.7926 137.545 11.9943H137.424V9.75284H133.512V27.7528ZM137.384 16.2983C137.384 14.1591 138.224 12.8381 139.671 12.8381C141.134 12.8381 141.942 14.1932 141.942 16.2983C141.942 18.4119 141.134 19.7841 139.671 19.7841C138.224 19.7841 137.384 18.4205 137.384 16.2983Z"
      fill="#03045E"
    />
  </svg>
);

export default WisestepLogo;
