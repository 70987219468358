enum JobType {
  Permanent = 'Permanent',
  Contract = 'Contract',
  Temporary = 'Temporary',
  FullTime = 'FullType',
  Internship = 'Internship',
  None = 'None',
  ContractToHireW2 = 'ContractToHireW2',
  ContractW2 = 'ContractW2',
  ContractIndependent = 'ContractIndependent',
  ContractToHireIndependent = 'ContractToHireIndependent',
  ContractCorpToCorp = 'ContractCorpToCorp',
  ContractToHireCorpToCorp = 'ContractToHireCorpToCorp',
  PartTime = 'PartTime',
  Freelance = 'Freelance',
  PermanentAndContract = 'PermanentAndContract',
  ThirdParty = 'ThirdParty',
}

export default JobType;
