import { isEmpty } from 'lodash';

import {
  candidateSearchAdvancedQuery,
  isCandidateDataAvailable,
  getCandidateAggregatedData,
  candidateSearchAdvancedQueryWithAggregations,
} from '../../CandidateSearchService';
import { IJob } from '../../JobSearchService';
import ICandidateSearchParams from '../types/ICandidateSearchParams';

export const initializeCandidateSearchObjectWithJobId = (
  searchParams: ICandidateSearchParams,
  jobId: string | null
) => {
  return {
    email: searchParams.email ? searchParams.email : '',
    linkedInURL: searchParams.linkedInURL,
    profileURLs: searchParams.candidateProfileURLs
      ? searchParams.candidateProfileURLs.map(function (url: any) {
          return url.constructor === {}.constructor ? url.url : url;
        })
      : searchParams.linkedInURL
      ? [searchParams.linkedInURL]
      : [],
    jobId: jobId ?? null,
    passportNumber: searchParams.passportNumber,
    phoneNumber: searchParams.phoneNumber,
  };
};

export const initializeCandidateSearchObject = (
  searchParams: ICandidateSearchParams,
  job: IJob | null,
  size: number
) => {
  return {
    candidateEmail: searchParams.email ? searchParams.email : '',
    linkedEmails: searchParams.linkedEmails,
    candidateProfileURLs: searchParams.candidateProfileURLs
      ? searchParams.candidateProfileURLs.map(function (url: any) {
          return url.constructor === {}.constructor ? url.url : url;
        })
      : searchParams.linkedInURL
      ? [searchParams.linkedInURL]
      : [],
    jobId: job ? job.id : null,
    candidateSearchType: searchParams.candidateSearchType ?? 'All',
    excludeDuplicateRecords: searchParams.excludeDuplicateRecords ?? false,
    page: searchParams.page ?? 0,
    searchAcross: ['TALENT_POOL_TEAMS_SHARING'],
    size: size,
    sort: searchParams.sort ?? ['modifiedOn,desc'],
    withLinkedAccounts: true,
  };
};

export const getCandidateHistory = async (
  searchParams: ICandidateSearchParams,
  job: IJob | null
) => {
  if (
    isEmpty(searchParams.email) &&
    isEmpty(searchParams.linkedInURL) &&
    isEmpty(searchParams.candidateProfileURLs) &&
    isEmpty(searchParams.linkedEmails) &&
    isEmpty(searchParams.passportNumber) &&
    isEmpty(searchParams.phoneNumber)
  ) {
    console.log('Error:: All the search params are empty.');
    return { mappingResults: [], aggregatedResults: [] };
  } else {
    if (!isEmpty(job) && job?.id) {
      const searchQuery = initializeCandidateSearchObject(searchParams, job, 100);
      const conversationResponse = await candidateSearchAdvancedQuery(searchQuery);
      if (isEmpty(conversationResponse.data)) {
        return conversationResponse.data;
      } else {
        const searchQueryWithoutJob = initializeCandidateSearchObject(searchParams, null, 1);
        const conversationResponseWithoutJob = await candidateSearchAdvancedQuery(
          searchQueryWithoutJob
        );
        return conversationResponseWithoutJob.data;
      }
    } else {
      const searchQueryWithoutJob = initializeCandidateSearchObject(searchParams, null, 1);
      const getCandidateCount = await isCandidateDataAvailable(searchQueryWithoutJob);
      return getCandidateCount.data;
    }
  }
};

export const candidateAggregatedData = async (
  searchParams: ICandidateSearchParams,
  job: IJob | null,
  size?: number
) => {
  if (
    isEmpty(searchParams.email) &&
    isEmpty(searchParams.linkedInURL) &&
    isEmpty(searchParams.candidateProfileURLs) &&
    isEmpty(searchParams.linkedEmails) &&
    isEmpty(searchParams.passportNumber) &&
    isEmpty(searchParams.phoneNumber)
  ) {
    console.log('Error:: All the search params are empty.');
    return { mappingResults: [], aggregatedResults: [] };
  } else {
    let searchQuery = initializeCandidateSearchObject(searchParams, job, size ?? 5);
    if (!searchParams.ignoreAggregation) {
      searchQuery = Object.assign(searchQuery, {
        aggregationFields: [
          'Email',
          'AlternateEmails',
          'ProfileURLs',
          'Tags',
          'PhoneNumber',
          'AlternatePhoneNumbers',
          'LinkedInUrl',
          'JobPreferencePermanent',
          'JobPreferenceContract',
          'CommPreferenceEmail',
          'CommPreferencePhone',
        ],
        pageIndex: 1,
        pageSize: 10,
      });
    }
    const getAggregatedData = await candidateSearchAdvancedQueryWithAggregations(searchQuery);
    if (!searchParams.ignoreAggregation) {
      return {
        count: getAggregatedData.headers['x-total-count'],
        data: {
          conversations: getAggregatedData.data.searchResult.content,
          aggregatedResults: getAggregatedData.data.aggregationResult,
        },
      };
    } else {
      return {
        count: getAggregatedData.headers['x-total-count'],
        data: {
          conversations: getAggregatedData.data.searchResult.content,
          aggregatedResults: getAggregatedData.data.aggregationResult,
        },
      };
    }
  }
};

export const candidateAggregatedDataWithJobId = async (
  searchParams: ICandidateSearchParams,
  jobId: string | null | undefined
) => {
  if (
    isEmpty(searchParams.email) &&
    isEmpty(searchParams.linkedInURL) &&
    isEmpty(searchParams.candidateProfileURLs) &&
    isEmpty(searchParams.linkedEmails) &&
    isEmpty(searchParams.passportNumber) &&
    isEmpty(searchParams.phoneNumber)
  ) {
    console.log('Error:: All the search params are empty.');
    return { mappingResults: [], aggregatedResults: [] };
  } else {
    const searchQuery = initializeCandidateSearchObjectWithJobId(searchParams, jobId ?? null);
    const getAggregatedData = await getCandidateAggregatedData(searchQuery);
    return getAggregatedData.data;
  }
};

export const newMappingEntity = () => {
  return {
    candidateId: '',
    recruiterJobMappingId: '',
    jobId: '',
    recruiterId: '',
    applicationStage: '',
    applicationState: '',
    mappedJobTitle: '',
    mappedJobLocation: '',
    name: '',
    profileURLs: [],
    email: '',
    linkedInURL: '',
    isJobCandidateMapping: null,
    alternateEmails: [],
    phoneNumber: '',
    alternatePhoneNumbers: [],
    skills: [],
    educationQualifications: [],
    institutionsAttended: [],
    certifications: [],
    currentJobTitle: '',
    currentLocation: null,
    currentEmployer: '',
    currentJobType: null,
    previousJobTitles: [],
    previousEmployers: [],
    totalYearsExperience: null,
    totalMonthsExperience: null,
    relevantYearsExperience: null,
    relevantMonthsExperience: null,
    noticePeriod: null,
    reasonForNewJob: '',
    assessment: '',
    currentSalaryCurrency: '',
    currentSalary: 0,
    currentSalaryTimeSpan: null,
    otherCurrentAllowances: '',
    expectedSalaryCurrency: '',
    expectedBaseSalary: 0,
    expectedBaseSalaryTimeSpan: null,
    otherExpectedAllowances: '',
    willingToRelocate: null,
    familyWillingToRelocate: null,
    relocationPreference: null,
    preferredLocations: null,
    jobIncludesTravel: false,
    currentJobTravelType: null,
    willingToTravel: null,
    interviewingWithOthers: false,
    otherInterviewStage: '',
    alreadyApplied: false,
    resumeFileOriginalName: '',
    resumeLocation: '',
    resumeContent: '',
    resumeUploadedOn: null,
    otherDocuments: [],
    nationality: '',
    workAuthorization: '',
    visaStatus: '',
    visaValidity: '',
    candidateNotes: [],
    customNote: '',
    messengers: [],
    sendMessage: false,
    showContact: false,
    workExperience: '',
    createdBy: '',
    createdOn: null,
    modifiedBy: '',
    modifiedOn: null,
    mappedCandidateResumes: [],
    candidateLanguages: [],
    source: '',
    workAuthorizationCategory: '',
    team: null,
    id: null,
    jobPreferencePermanent: null,
    jobPreferenceContract: null,
    commPreferenceEmail: null,
    commPreferencePhone: null,
    jobLocations: null,
    socialProfiles: null,
    tags: null,
    isInDnc: null,
    dncDetails: null,
    dnc: null,
    turnAroundTime: null,
    jobCode: null,
    clientJobCode: null,
    companyName: null,
    jobType: null,
    noticePeriodInDays: null,
    recruiterName: null,
    candidateInteractionResult: null,
    contactActions: null,
    contactActionsObjs: null,
    stageAndStateActions: null,
    candidateTasks: null,
    rtrFileOriginalName: null,
    rtrFileLocation: null,
    linkedCandidateIds: null,
    linkedCandidateContacts: null,
    responseData: null,
    stageDate: null,
    jobWorkAssignment: null,
    recruiterCompanyName: null,
    jobAccountManagerName: null,
    jobAccountManagerEmail: null,
    jobClientRecruiterName: null,
    jobClientRecruiterEmail: null,
    isCreatedWhenCandidateMapped: true,
    jobPostedByRecruiterName: null,
    jobPostedByTeam: null,
    jobPostingDate: null,
    isFromCareerPage: null,
    candidateStatusTags: [],
    fax: null,
    interests: null,
    desiredJobTitles: null,
    workPermitDocuments: null,
    imageUrl: null,
    likelyToMove: null,
    likelyToMoveScore: null,
    hasVeteranStatus: null,
    dateLastActiveOnJobBoard: null,
    dateResumeLastUpdatedOnJobBoard: null,
    experience: null,
    workPermitLocations: null,
    securityAllowance: null,
    bio: null,
    citizenship: null,
    candidateJobBoardId: null,
    downloadSource: null,
    scoringData: null,
    workPreferences: null,
    candidateSkills: null,
    lastSmsAction: null,
    lastCallAction: null,
    isSuitable: null,
    isInterested: null,
    calendarEvents: null,
    searchId: null,
    isMapped: null,
    isApplied: null,
    isSubmitted: null,
    isUploaded: null,
    willingToTravelType: null,
    rtrFileUploadedOn: null,
    passportNumber: '',
    passportType: '',
    dob: '',
    age: '',
    gender: null,
    postalAddress: null,
    ssnNumber: null,
    panCard: null,
    aadharNumber: null,
  };
};

export const numFormatter = (num: number) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(0) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + 'M'; // convert to M for number from > 1 million
  } else if (num > 1000000000) {
    return (num / 1000000).toFixed(0) + 'B'; // convert to B for number from > 1 billion
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
};

export const getCandidateAggregationDataForGDPR = async (
  workSpaceId: string,
  email: string,
  postedByTeam: string[]
) => {
  if (isEmpty(email)) {
    console.log('Error:: All the search params are empty.');
    return { mappingResults: [], aggregatedResults: [] };
  } else {
    const searchQuery = {
      candidateEmail: email,
      workspaceId: workSpaceId,
      excludeAddingLoggedInUserAsRecruiterIdByDefault: true,
      candidateSearchType: 'All',
      excludeDuplicateRecords: false,
      withLinkedAccounts: true,
      size: 10,
      sort: ['modifiedOn,desc'],
      postedByTeams: postedByTeam.toString(),
      aggregationFields: [
        'Email',
        'AlternateEmails',
        'Tags',
        'PhoneNumber',
        'AlternatePhoneNumbers',
        'LinkedInUrl',
      ],
      pageIndex: 1,
      pageSize: 10,
    };

    const getAggregatedData = await candidateSearchAdvancedQueryWithAggregations(searchQuery);
    return {
      count: getAggregatedData.headers['x-total-count'],
      data: {
        conversations: getAggregatedData.data.searchResult.content,
        aggregatedResults: getAggregatedData.data.aggregationResult,
      },
    };
  }
};

export const newJobBoardCandidateEntity = () => {
  return {
    candidateId: '',
    currentJobTitle: '',
    currentLocation: null,
    skills: [],
    totalYearsExperience: null,
    name: '',
    willingToRelocate: null,
    email: '',
    likelyToMove: null,
    likelyToMoveScore: null,
    workAuthorization: '',
    workPreferences: null,
    alternateEmails: [],
    phoneNumber: '',
    alternatePhoneNumbers: [],
    dateResumeLastUpdatedOnJobBoard: null,
    currentSalary: 0,
    currentSalaryCurrency: '',
    currentSalaryTimeSpan: null,
  };
};
