import { Dispatch, SetStateAction } from 'react';

import IComposableAnalyticsGlobalFilters from './IComposableAnalyticsGlobalFilters';
import IComposableChartsWidget from './IComposableChartsWidget';
import IComposableDashboard from './IComposableDashboard';

export interface IComposableDashboardContext {
  dashboards?: IComposableDashboard[];
  setDashboards?: Dispatch<SetStateAction<IComposableDashboard[]>>;
  selectedIndex?: number;
  setSelectedIndex?: Dispatch<SetStateAction<number>>;
  selectedDashboard?: IComposableDashboard;
  setSelectedDashboard?: Dispatch<SetStateAction<IComposableDashboard>>;
  addWidget?: (chartObj: IComposableChartsWidget) => void;
  removeWidget?: (chartObj: IComposableChartsWidget) => void;
}

export enum ComposableDashboardFiltersEnum {
  RECRUITER = 'RECRUITER',
  CLIENT = 'CLIENT',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  STATUS = 'STATUS',
  TEAM = 'TEAM',
}

export const composableDashboardFiltersLabelValue: Record<
  ComposableDashboardFiltersEnum,
  { label: string; value: Record<string, any> }
> = {
  [ComposableDashboardFiltersEnum.RECRUITER]: {
    label: 'Recruiter Filter',
    value: { recruiterNames: [] },
  },
  [ComposableDashboardFiltersEnum.CLIENT]: {
    label: 'Client Filter',
    value: { clientNames: [] },
  },
  [ComposableDashboardFiltersEnum.ACCOUNT_MANAGER]: {
    label: 'Account Manager Filter',
    value: { accountManagers: [] },
  },
  [ComposableDashboardFiltersEnum.STATUS]: {
    label: 'Status Filter',
    value: { status: '', candidateStageStatusPipelineData: '' },
  },
  [ComposableDashboardFiltersEnum.TEAM]: {
    label: 'Teams Filter',
    value: { teamIds: [] },
  },
};

export interface IComposableDashboardFiltersContext {
  filters?: IComposableAnalyticsGlobalFilters;
  setFilters?: Dispatch<SetStateAction<IComposableAnalyticsGlobalFilters>>;
  filtersAdddedToDashboard?: ComposableDashboardFiltersEnum[];
  addFilterToDashboard?: (filter: ComposableDashboardFiltersEnum) => void;
  removeFilterFromDashboard?: (filter: ComposableDashboardFiltersEnum) => void;
}
