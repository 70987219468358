import { Grid, styled, useTheme, Link, FormControlLabel } from '@mui/material';
import { Item, WisestepLogo } from 'wisestep-base';

const BottomElement = styled(Grid)(() => ({
  position: 'fixed',
  bottom: '0px',
  textAlign: 'center',
  left: 0,
  right: 0,
}));

const AppFooter = () => {
  const theme = useTheme();
  const StyledItem = styled(Item)(() => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1.5),
    maxHeight: 50,
    verticalAlign: 'middle',
    height: 50,
    '& a': {
      color: theme.palette.primary.contrastText,
    },
  }));

  return (
    <BottomElement>
      <StyledItem>
        <FormControlLabel
          label={'Powered By'}
          labelPlacement="start"
          control={
            <Link pl={1} href="https://recruiter.wisestep.com/#/register?utm_source=careerpages">
              <WisestepLogo />
            </Link>
          }
        />
      </StyledItem>
    </BottomElement>
  );
};

export default AppFooter;
