import axios from 'axios';

import IAnalyticsJobSearchFilterDTO from '../../../types/IAnalyticsJobSearchFilterDTO';

const url = 'wsreporting/api/';

export const getRealTimeTotalJobsPosted = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-total-jobs-posted`, searchFilter);

export const getRealTimeJobsByClients = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-jobs-by-client`, searchFilter);

export const getRealTimeJobsByClientContacts = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-jobs-by-client-contacts`, searchFilter);

export const getRealTimeJobsWithZeroSubmissions = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-jobs-with-zero-submissions`, searchFilter);

export const getRealTimeJobsByJobType = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-jobs-by-job-type`, searchFilter);

export const getRealTimeWeeklyJobs = (searchFilter: IAnalyticsJobSearchFilterDTO) =>
  axios.post(`${url}get-real-time-weekly-jobs`, searchFilter);
