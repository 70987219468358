import { createContext, useContext, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IAppContext } from 'wisestep-api';
import { Error as ErrorComponent } from 'wisestep-base';

import NotFound from '../components/NotFound';
import Spinner from '../components/spinner';
import { queryClient } from '../lib/react-query';

import useFetchSettings from './useFetchSettings';

export const AppContext = createContext<IAppContext>({} as IAppContext);

type AppProviderProps = {
  children: React.ReactNode;
};
export default function AppContextProvider({
  children,
}: React.PropsWithChildren<AppProviderProps>) {
  const [fetching, notFound, settings] = useFetchSettings();
  return (
    <Suspense fallback={<Spinner />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}

          {!notFound && !fetching && (
            <AppContext.Provider value={settings}>{children}</AppContext.Provider>
          )}
          {notFound && (
            <>
              <NotFound />
            </>
          )}
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw Error();
  }
  return context;
}

function ErrorFallback() {
  return <ErrorComponent pageRefresh={false} />;
}
