export enum InvoiceStatus {
  DRAFT,
  ISSUED,
  OVERDUE,
  PAID,
  PARTIALLY_PAID,
  CHANGES_REQUIRED,
  REJECTED_BY_CLIENT,
}

export const invoiceStatusMap: Record<InvoiceStatus, string> = {
  [InvoiceStatus.DRAFT]: 'Draft',
  [InvoiceStatus.ISSUED]: 'Issued',
  [InvoiceStatus.OVERDUE]: 'Over Due',
  [InvoiceStatus.PAID]: 'Paid',
  [InvoiceStatus.PARTIALLY_PAID]: 'Partially Paid',
  [InvoiceStatus.CHANGES_REQUIRED]: 'Changes Required',
  [InvoiceStatus.REJECTED_BY_CLIENT]: 'Rejected By Client',
};
