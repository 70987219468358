export enum FieldTypeEnum {
  TextBox = 'TextBox',
  Number = 'Number',
  Date = 'Date',
  Location = 'Location',
  DateAndTime = 'DateAndTime',
  URL = 'URL',
  Tags = 'Tags',
  Dropdown = 'Dropdown',
  Users = 'Users',
  Checkbox = 'Checkbox',
  ClientSPOC = 'ClientSPOC',
}

export interface Field {
  label: string;
  description: string;
  required: boolean;
  type: FieldTypeEnum;
  visible: boolean;
  name: string;
  options: string[];
  multiple?: boolean;
  id?: string;
  hardcoded?: boolean;
}
