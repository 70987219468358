export enum LogAction {
  Add = 'Add',
  Delete = 'Delete',
  Edit = 'Edit',
  Assigned = 'Assign',
  UnAssigned = 'Unassign',
}

export enum LogContextType {
  Client = 'Client',
  Placement = 'Placement',
  Invoice = 'Invoice',
  OurEntity = 'OurEntity',
}

export enum LogEntityType {
  InvoiceShare = 'InvoiceShare',
  Candidate = 'Candidate',
  Fee = 'Fee',
  LientRecruiter = 'LientRecruiter',
  File = 'File',
  FileCategory = 'FileCategory',
  Template = 'Template',
  Position = 'Position',
  SMS = 'SMS',
  Email = 'Email',
  Reminder = 'Reminder',
  Note = 'Note',
  Call = 'Call',
  OtherDocuments = 'OtherDocuments',
  Transcation = 'Transcation',
  Placement = 'Placement',
  OurEntity = 'OurEntity',
  Placed = 'Placed',
  Invoices = 'Invoices',
  Documents = 'Documents',
  ClientContact = 'ClientContact',
  Bank = 'Bank',
  Legal = 'Legal',
  Client = 'Client',
  Invoice = 'Invoice',
  Taxation = 'Taxation',
  Signatory = 'Signatory',
}

export interface ActivityLogDTO {
  id: string | null;
  field: string;
  entityId: string;
  parentEntitiyId: string;
  entityName: string;
  description: string;
  parentEntitiyName: string;
  action: LogAction;
  entityType: LogEntityType;
  parentEntitiyType: LogContextType;
  createdOn: Date;
  createdByName: string;
  workspaceId: string;
  createdBy: string;
  contextData: any;
}
