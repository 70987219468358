enum JobBoardNames {
  DICE_SEARCH = 'DICE_SEARCH',
  CAREER_BUILDER = 'CAREER_BUILDER',
  CAREER_BUILDER_EDGE = 'CAREER_BUILDER_EDGE',
  TECH_FETCH = 'TECH_FETCH',
  MONSTER = 'MONSTER',
  MONSTER_US = 'MONSTER_US',
  MONSTER_EU = 'MONSTER_EU',
  JOBSERVE = 'JOBSERVE',
  CW_JOBS = 'CW_JOBS',
  CV_LIBRARY = 'CV_LIBRARY',
  JOBSITE = 'JOBSITE',
}
export default JobBoardNames;
