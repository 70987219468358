const WsColors = {
  TheBlues950: '#337ab7',
  TheBlues900: '#03045E',
  TheBlues800: '#023E8A',
  TheBlues700: '#0077B6',
  TheBlues600: '#0096C7',
  TheBlues500: '#00B4D8',
  TheBlues400: '#48CAE4',
  TheBlues300: '#90E0EF',
  TheBlues200: '#ADE8F4',
  TheBlues100: '#CAF0F8',
  TheBlues50: '#F2FBFD',
  TheBlues25: '#FAFAFC',
  TertiaryShades900: '#484D6D',
  TertiaryShades800: '#616893',
  TertiaryShades700: '#6F75A0',
  TertiaryShades600: '#7E84AA',
  TertiaryShades500: '#A0A4C0',
  TertiaryShades400: '#A9ADC6',
  TertiaryShades300: '#CED0DF',
  TertiaryShades200: '#DDDEE8',
  TertiaryShades100: '#EAEAF1',
  TertiaryShades50: '#F6F6F9',
  Green500: '#02D8CE',
  Green400: '#12EDE3',
  Green300: '#84F1EC',
  Green200: '#BBF2EF',
  Green100: '#E0F5F4',
  Red500: '#FF2E49',
  Red400: '#F85D72',
  Red300: '#FB93A0',
  Red200: '#FDC9D0',
  Red100: '#FED8DD',
  Yellow500: '#FFC42E',
  Yellow400: '#F8D372',
  Yellow300: '#F6E1AC',
  Yellow200: '#F6EBD0',
  Yellow100: '#F9F4E7',
  BorderGrey: '#EAEAF1',
  GreyText: '#11151C',
  Red: '#FF0000',
  GreyBlack: '#A0A4C0',
  White: '#FFFFFF',
  GenericRichBlack: '#11151C',
  YellowCompliment: '#AE7D00',
  None: '',
  BackGround: '#f5f5f5',
};

export default WsColors;
