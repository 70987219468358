import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export default styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));
