import { Box, Container, styled, Typography } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(20),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
}));
const Error = () => (
  <Container component="main" maxWidth="xs" sx={{ verticalAlign: 'center' }}>
    <StyledBox>
      <Box component="img" src="/Branding.svg" height={60} p={1}></Box>
      <Typography variant="h5">No Career Page Found with given name</Typography>
    </StyledBox>
  </Container>
);

export default Error;
